.sidenav{
    width: 25%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    background: #FFFFFF;
    box-shadow: 10px 4px 50px rgba(41, 131, 197, 0.15);
    min-width: 270px;
    &-container{
        margin-top: 30px;
        width: 80%;
        display: flex;
        flex-direction: column;
        &-username{
            display: flex;
            align-items: center;
            &-text{
                font-weight: 500;
                color: #252D59;
                
            }
            &-img{
                display: flex;
                border-radius: 50%;
                align-items: center;
                justify-content: center;
                font-weight: 500;
                // color: white;
                margin-right: 8px;
                width: 43px;
                height: 43px;
                background-color: #DCDDDD;
            }
        }
        &-list{
         list-style: none;
         margin-top: 20px;
         a{
            color: inherit;
           text-decoration: none;
        }
         &-item{
             display: flex;
             align-items: center;
             margin: 15px 0px;
             padding: 8px 0px;
             cursor: pointer;
             border-radius: 6px;
             text-align: center;
             color: #595757;
            
             
             &-active{
                 background-color: #DCDDDD;
             }
             img{
                 margin: 0px 15px;
             }
            &:hover{
                background-color: #DCDDDD;
            }
         }
        }
    }
 
    
}

@media (max-width: "1100px") {
    // height: 10vh !important;
    .sidenav{
        width: 100%;
        min-height: 10vh;
        display: flex;
        justify-content: center;
        background: #FFFFFF;
        box-shadow: 10px 4px 50px rgba(41, 131, 197, 0.15);
        min-width: 270px;
    &-container{
        margin: 0;
        width: 100%;
        @media (max-width: "550px") {
            width: 94%;
        }
        display: flex;
        // flex-direction: column;
        &-logo{
            display: none;
        }
        &-username{
            display: none;
           
        }
        &-list{
         list-style: none;
         width: 100%;
         margin: 0;
         display: flex;
        //  flex-direction: column;
         justify-content: space-around;
         @media (max-width: "770px") {
            justify-content: space-between;
         }
         a{
            color: inherit;
           text-decoration: none;
        }
         &-item{
             display: flex;
             align-items: center;
            //  margin: 15px 0px;
             padding: 8px 15px;
             cursor: pointer;
             border-radius: 6px;
             @media (max-width: "770px") {
                flex-direction: column;
                padding: 5px 5px;
                img{
                    margin: 0px 15px;
                    margin-bottom: 5px !important;
                }
             }
            
             
             &-active{
                 background-color: rgba(41, 131, 197, 0.13);
             }
             img{
                 margin: 0px 15px;
             }
            &:hover{
                background-color: rgba(41, 131, 197, 0.13);
            }
         }
        }
    }
}
}