.dashboard{
  &-container{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
//   &-table{
//     width: 100%;
//     margin-top: 15px;
//     &-head{
//         display: flex;
//         justify-content: space-between;
//         background-color: #252D59;
//         padding: 10px 0px;
//         &-feedback{
//           width: 40% !important;
//         }
//         &-sort{
//           display: flex;
//           align-items: center;
//           justify-content: center;
//           img{
//             padding: 2px 2px;
//             cursor: pointer;
//             border: 1px solid white;
//             margin-left: 8px;
//             border-radius: 5px;
//           }
//         }
        
//         span{
//             color: white;
//             width: 25%;
//             text-align: center;
//         }
//     }

//     &-data{
//         display: flex;
//         justify-content: space-between;
//         border-bottom: 1px solid black;
//         padding: 15px 0px;
//         font-size: 0.95rem;
//         span{
//             // color: white;
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             width: 25%;
//             text-align: center;
//         }
//         &-context{
//           width: 65% !important;
//           justify-content: flex-start !important;
//           margin-left: 9.5%;
//           &-untagged{
//             display: flex;
//             margin-left: 10px;
//             justify-content: space-between !important;
//             width: 24% !important;
//             &-status{
//               font-weight: 500;
//               &-resolved{
//                 color: green;
//               }
//               &-removed{
//                 color: red;
//               }
//             }
           
//             &-text{
//               justify-content: flex-start !important;
//               width: 95% !important;
//               margin-left: 5px;
//               text-align: left !important;
//             }
//             img{
//               cursor: pointer;
//               width: 20px;
//             }
//           }

//           &-feedback{
//             display: flex;
//             margin-left: 10px;
//             justify-content: space-between !important;
//             width: 40% !important;
//             &-status{
//               font-weight: 500;
//               &-resolved{
//                 color: green;
//               }
//               &-removed{
//                 color: red;
//               }
//             }
           
//             &-text{
//               justify-content: flex-start !important;
//               width: 95% !important;
//               margin-left: 5px;
//               text-align: left !important;
//             }
//             img{
//               cursor: pointer;
//               width: 20px;
//             }
//           }
//         }
//         &-action{
//             display: flex;
//             align-items: center;
//             text-align: left !important;
//             justify-content: space-between !important;
//             &-btn{
//                 display: flex !important;
//                 justify-content: flex-end !important;
//                 width: 20% !important;
//             }
//             img{
//                 cursor: pointer;
//             }
//         }
//     }
// }
// &-page{
//   display: flex;
//   width: 100%;
//   font-size: 1rem;
//   margin-top: 5px;
//   justify-content: flex-end;
// }
// &-btns{
//   margin-top: 20px;
//   width: 100%;
//   display: flex;
//   justify-content: flex-end;
//   div{
//       display: flex;
//       align-items: center;
//       font-size: 1rem;
//       cursor: pointer;
//   }
//   span{
//       cursor: pointer;
//       margin: 5px;
//       background-color: #252D59;
//       color: white;
//       font-size: 2rem;
//       width: 35px;
//       height: 35px;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       border-radius: 50%;
//   }
// }
//   &-heading{
//     margin-top: 20px;
//     display: flex;
//     justify-content: space-between;
//     &-date{
//       // width: 50%;
//       display: flex;
//       align-items: center;
//       &-picker{
//         width: 220px;
//       }
//       input{
//         border-radius: 10px;
//         border: 1px solid #252D59;
//         padding: 5px 10px;
//       }
//       &-export{
//         font-size: 0.8rem !important;
//         cursor: pointer;
//         display: flex;
//         color: white;
//         align-items: center;
//         border-radius: 5px;
//         margin-left: 8px;
//         padding: 5px;
//         background-color: #252D59;
//         img{
//           // margin-right: 1px;
//           margin-left: 5px;
//           width: 15px;
//         }
//       }
//     }
//     span{
//       font-size: 1.2rem;
//     }
//   }
  &-dougnet{
    margin-top: 15px;
    border-radius: 10px;
    border: 1px solid black;
    width: 450px;
    min-width: 45%;
    height: 314px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
   
    div{
      margin-top: 20px;
      width: 78%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-top{
      top: 0%;
      position: absolute;
      display: flex;
      margin-top: 10px !important;
      // margin: 20px;
      
      padding: 0px 15px;
      font-size: 1.2rem;
      width: 100% !important;
      justify-content: space-between !important;
      &-text1{
        font-weight: 500;
        color: #595757;
      }
      &-text2{
        display: flex;
        font-size: 1rem;
        align-items: center;
        color:  #595757;
        font-weight: 500;
        width: 40%;
        span{
          // display: flex;
          display: block;
          // justify-content: space-between;
          width: 40px;
          height: 40px;
          background: #595757;
          margin: 0px 20px;
          padding: 0px 20px;
        }
      }
    }
    // width: 400px;
    // height: 400px;
  }
&-chart{

  &-rating{
    width: 90%;
    text-align: left;
    color: #D80C18;
    font-size: 1.1rem;
  }
  &-top{
   margin-top: 15px;
    display: flex;
    justify-content: space-between;
    width: 90%;
    span{
      font-weight: 500;
      font-size: 1.1rem;
      color: #595757;
    }
    select{
      padding: 5px;
      border-radius: 15px;
      color: #252D59;
      border: 1px solid #F26729;
      cursor: pointer;

    }
  }
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border:  1px solid #252D59;
  width: 450px;
  min-width: 50%;

}

&-inputContainer{
  margin-top: 10px;
  width: 100%;
  padding: 10px;
  background-color:  #E3EFF7;
  display: flex;
  align-items: center;
  input{
    width: 100%;
    font-size: 1.1rem;
    background-color: transparent;
    outline: none;
    border: none;
  }
  img{
    margin-right: 10px;
    width: 18px;
  }
}
    &-table-tabs {
      color: #595757;
      display: flex;
      width: 100%;
      // justify-content: space-between;
      // margin: 10px 0px ;
      margin-top: 50px;
      // padding-bottom: 15px;
      border-bottom: 4px solid #EBEBEB;
      &-item {
        
        padding: 0px 5px;
        padding-bottom: 10px;
        margin: 0px 10px;
        cursor: pointer;
        border-bottom: 4px solid transparent;
        &:hover{
          margin: 0px 10px;
          color: #D80C18;
          font-weight: 600;
          border-bottom: 4px solid #D80C18;
        }
        &-active{
          margin: 0px 10px;
          color: #D80C18;
          font-weight: 600;
          border-bottom: 4px solid #D80C18;
        }
        // width: 100%;
      }
     
    }
}


// #BarChart { 
//     display: flex; 
//     flex-direction: row; /* Arrange the Bars Horizontally using FlexBox  */
//     align-items: flex-end; /* Have the bars start at the bottom and go up */
//     justify-content: center; /* Center the bars in the middle of the screen */
//     margin: 50px;
//     border-bottom: 1px solid black;
//   }
  
//   .bar {
//       // height: auto;
//       display: flex;
//       flex-direction: column;
//       align-items: center;
//       // max-height: 500px;
//       justify-content: space-between;
//     background: linear-gradient(180.55deg, #2983C5 0.47%, #252D59 134.06%);
//     border-radius: 6px 6px 0px 0px;
//     cursor: pointer;
//     &:not(:first-child){
//       span{
//         // color: black;
//       }
     
//     }
//     &:hover{
//         transition: all 0.5s ease;
//         background: linear-gradient(180.55deg, #286895 0.47%, #252D59 134.06%);
//     }
//     span{
//         margin-top: 5px;
//         color: white;
//         font-size: 1.5rem;
//     }
//      /* Have each bar be a nice green */
//     width: 10px; /* The animation will start with each bar being 10px wide, and then expand outwards */
//   }