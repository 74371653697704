.question{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }
  .form{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 53px;
    z-index: 9999;
    width: 100% !important;
    background-color: #EBEBEB;
    padding: 0px 10px !important;
  }
  .form-parent{
    background-color: #595757;
    color: white;
    width: 100% !important;
  }
  .form-title{
    max-width: 800px;
    display: flex;
    z-index: 9999;
  }
  .form-title{
    overflow-wrap: break-word;
  }
  
  
  .rst__moveHandle{
    height: 53px !important;
    color: white; font-size: 1.1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto !important;
  
  }
  .rst__node{
    /* height: 100% !important; */
    position: relative !important;
    top: 0px !important;
    vertical-align: top !important;
  }
  .rst__row{
     /* height: 100% !important; */
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
     background-color: white;
     /* width: 100% !important; */
    white-space: normal !important;
    
  }
  .rst__tree{
    height: min-content !important;
    min-height: 100%;
    position: relative !important;
    /* min-height: 2500px !important; */
  }

  .rst__tree > div{
    height: 100% !important;
    /* min-height: 2500px !important; */
  }
  .container{
    height: 100%;
    width: 100%;
    max-height: 73vh; 
    margin-top: 10px;
    /* margin-top: 70px; */
    // /* overflow-y: scroll; */
    overflow: auto;
   
  }
  @media (max-width: 1100px) {
    .container{
      height: 70vh !important;
      width: 100%;
      /* max-height: 75vh; */
      margin-top: 10px;
      /* margin-top: 70px; */
      /* overflow-y: scroll; */
      overflow: auto;
    }
  }
  
  .rst__rowLabel{
    display: flex !important;
    justify-content: space-between ;
    padding: 0px !important;
  }
  .rst__rowContents{
    border: none !important;
    padding: 0 !important;
    display: block !important;
    background-color: white !important;
    box-shadow: none;
    
  }
  .ReactVirtualized__Grid__innerScrollContainer{
    height: auto  !important;
    /* overflow-x: scroll !important; */
    /* width: 600px !important; */
  }
  .rst__moveHandle{
    /* background-color: white; */
    margin-left: -2px;
    box-shadow: none;
    min-width: 44px;
    background: none;
    border: none;
    background-color: #D80C18;
  }
  
  .rst__nodeContent{
   
    width: 85%;
  }
  
  .rst__rowTitle{
    z-index: 9999;
    width: 100%;
  }
  .ReactVirtualized__Grid, .ReactVirtualized__List, .rst__virtualScrollOverride{
  /* position: absolute !important; */
  height: 100% !important;
  }
  .rst__virtualScrollOverride{
    overflow: visible !important;
  }

  .questionNumber{
    padding: 5px;
  }
  .addNewbtn{
    display: inline-block;
    // margin-top: 30px;
    // margin: 10px;
    border-radius: 10px;
    padding: 10px 10px;
    background-color: #595757;
    color: white;
    cursor: pointer;
  }
  .search-area{
    width: 65%;
    margin: 10px 0px;
    display: flex;
    align-items: center;
    font-size: 0.8rem;
    label{
      font-size: 12px;
    }
    span{
      padding: 5px;
      
      width: 70px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0px 5px;
      cursor: pointer;
      background-color: #595757;
      color: white;
      border-radius: 5px;
    }
    &-input{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 15px;
     
      &-container{
          display: flex;
          align-items: center;
          background-color:  #EBEBEB;
          padding: 5px 10px;
          margin-right: 10px;
          width: 70%;
          position: relative;
          img{
              width: 18px;
              margin-right: 5px;
          }
          input{
              background-color: transparent;
              outline: none;
              padding: 9px 8px;
              border: none;
              font-size: 1rem;
              width: 100%;
          }
          button{
            border: none;
            cursor: pointer;
            padding: 5px 15px;
            border-radius: 5px;
            background-color: #252D59;
            color: #fff;
            outline: none;
            &:disabled{
              opacity: none;
              display: none;
            }
        }
          
      }
    }
  }
  .top-header{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .rst__rowSearchMatch ,.rst__rowSearchFocus{
    outline: none !important;
  }