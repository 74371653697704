.answer-text{
    /* width: 100%; */
    height: 100%;
    display: flex;
    padding: 5px;
    overflow-y: auto;
    flex-direction: column;
    word-wrap: break-word;
   
    /* bottom: 8%; */
    /* height: 100px !important; */
    background-color: white;
    /* position: absolute; */
}

.answer{
    height: 78%;
    width: 100%;
    position: absolute;
    padding-top: 10px;
}