.model {
    position: fixed;
    top: 50%;
    left: 60%;
    @media (max-width: 1100px) {
        left: 50%;
    }
    transform: translate(-50%, -50%);
    background-color: #ffff;
    height: 650px;
    width: 900px;
    box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 15px;
    &-btn{
        background-color: #252D59;
        color: white;
        cursor: pointer;
        padding: 8px 25px;
        border-radius: 5px;
        margin-top: 10px;
    }
    &-close {
        top: -1%;
        right: -1%;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.7rem;
        transform: rotate(45deg);
        cursor: pointer;
        color: white;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: gray;
    }
    form{
        height: 100%;

       }
    &-container {
        position: relative;
        label{
            margin: 5px 0px;
            text-align: left;
            width: 92%;
            color: #595757;
            span{
                color: #D80C18;
            }
        }
        &-error{
            position: absolute;
            top: 0%;
            z-index: 99999;
            color: #D80C18;
        }
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 100%;
        align-items: center;

        // padding: 5px;
        input {
            width: 90%;
            background-color: #EBEBEB;
            padding: 15px 10px;
            border: none;
        }

        &-upload {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 0.8rem;
            // margin-top: 15px;
            label{
                width: 90px;
            }
            &-preDoc{
               &-docname{
                   padding: 15px 0px;
               }
                width: 100%;
                justify-content: space-between;
                align-items: center;
                display: flex;
                img{
                    width: 25px;
                    cursor: pointer;
                }
            }
            width: 92%;
            background-color: #EBEBEB;
            padding: 0px 10px;
            input[type="file"]{
                visibility: hidden;
                display: none;
            }
            &-button{
                
                margin-left: auto;
                background-color: #595757;
                color: white !important;
                cursor: pointer;
                margin-right: 5px;
                padding: 7px 8px;
                border-radius: 5px;
            }

        }
        &-answer{
            margin: 5px 0px;
            width: 92%;
            height: 40%;
            position: relative;
        }
        &-textarea {
            height: 75%;
            &-charector{
                position: absolute;
                right: 1%;
                top: 12.5%;
                // margin-top: -55px;
                font-size: 0.9rem;
            }
            &-error{
                display: none;
                color: red;
                font-size: 0.8rem;
                position: absolute;
                z-index: 9999;
                top: 0%;
                right: 0%;
            }
            width: 100%;
            // height: inherit;
            background-color: #F0F6FB;
            margin-bottom: 40px;
        }
    }

    /* position: absolute; */
}

.ql-editor {
    background-color: #EBEBEB;
}