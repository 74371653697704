.TitleComponent{
    margin-right: -2px;
    form{
        display: flex;
        align-items: center;
        // height: 100%;
        img{
            width:  25px;
        }
        span{
            cursor: pointer;
            margin-right: 8px;
        }
    }
    &-copy{
        margin-top: -6px;
        background-color: #595757;
        padding: 1px 7px;
        border-radius: 5px;
        img{
            
            width: 12px !important;
            // background-color: white;
        }
    }
    &-add{
        font-size: 0.9rem;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 100px;
        span{
            font-size: 1.5rem;
        }
        padding: 0px 10px;
        height: 30px;
        border-radius: 15px;
        border: 1.5px solid white;
    }
}
.form{
    &-btns{
        margin-left: 10px;
        display: flex;
        align-items: center;
    }
    &-p{
        border: 1px solid white;
        border-radius: 5px;
        
        width: 25px !important;
        margin: 0px 5px;
        margin-top: 5px;
    }
}