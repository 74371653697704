.newuser-container{
    min-height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: "1100px") {
        min-height: 70vh;
    }
}
.newuser{
    margin-top: 20px;
    width: 100%;
    border: 1.5px solid #252D59;
    @media (max-width: "770px") {
        border: none;
    }
    border-radius: 10px;
    padding: 30px 0px;
    min-height: 95%;
    display: flex;
    flex-direction: column;
    &-title{
        margin-left: 20px;
        font-weight: 600;
        font-size: 1.2rem;
        color: #595757;
    }
    &-popup{
        top: 3%;
        right: 3%;
        position: absolute;
        // width: 150px;
        font-size: 0.8rem;
        padding: 10px 15px;
        text-align: center;
        &-green{
            background-color: rgb(30, 166, 30);
        }

        &-red{
            background-color: red;
        }
        animation: moveTop 1s;
        color: white;
        span{
            top: -10%;
            right: 1%;
            position: absolute;
            transform: rotate(45deg);
            cursor: pointer;
            font-size: 1.2rem;
        }
    }
    &-form{
        display: flex;
        flex-wrap: wrap;
        &-password{
            display: flex;
            position: relative;
            &-suggested{
                display: none !important;
                color: #B5B6B6;

                cursor: pointer;
                position: absolute;
                top: 84%;
                display: flex;
                box-shadow: 0px 0px 20px 1px rgba(41, 131, 197, 0.13);
                border-radius: 10px;
                background-color: white;
                width: 92.5% !important;
                &-top{
                    flex-direction:  row !important;
                    justify-content: space-between !important;
                    width: 100% !important;
                    border-bottom: 1px solid rgba(37, 45, 89, 0.32);
                    span{
                        text-align: center;
                        // width: 50% !important;
                        font-size: 0.9rem;
                    }
                }
                &-bottom{
                    width: 100% !important;
                    span{
                        font-size: 0.9rem;
                    }
                }
                // &-disabled{
                //     display: none !important;
                // }
            }
            
        }
        div{
            display: flex;
            flex-direction: column;
            padding: 10px 20px;
            margin-top: 10px;
            width: 50%;
            @media (max-width: "940px") {
                width: 100%;
            }
            min-width: 250px;
            input,select{
                font-size: 1.1rem;
                padding: 8px;
                border: 1px solid rgba(37, 45, 89, 0.35);
                border-radius: 5px;
                outline: none;
            }
            label{
                color: #252D59;
                font-size: 0.9rem;
                margin-bottom: 6px;
            }
        }
    }
    &-btn{
        width: 170px;
        margin-left: 20px;
        font-weight: 500;
        padding: 10px 20px;
        border-radius: 6px;
        background-color: #595757;
        cursor: pointer;
        margin-top: auto;
        font-size: 1rem;
        border: none;
        outline: none;
        color: white;
    }
   
}

@keyframes moveTop {
    from {top: 6%;}
    to {top: 3%;}
  }