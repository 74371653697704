.header{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    &-title{
        display: flex;
        align-items: center;
        img{
            margin-left: 20px;
            // width: 35px;
        }
        span{
            font-size: 1.5rem;
            font-weight: 600;
            color: #595757;
        }   
       
    }
    &-logout{
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            padding: 5px 20px;
            border: 1px solid #D80C18;
            border-radius: 50px;
            span{
                font-weight: 500;
                font-size: 0.9rem;
                color: #595757;
            }
            img{
                width: 17px;
                margin-left: 10px;
            }
        
    }
}