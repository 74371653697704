.login{
    display: flex;
    flex-direction: column;
    background-color: #fff;
    width: 100%;
    justify-content: center;
    height: 100vh;
    &-img{
        @media (max-Width: 880px) {
            display: none;
        }
        width: 40%;
        img{
            width: 100%;
        }
    }

    &-logo{
        padding-top: 20px;
        padding-left: 50px;
    }

    &-page{
        display: flex;
        height: 90%;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        &-container{
            height: 95%;
            width: 50%;
           
            max-width: 600px;
            min-width: 300px;
            @media (max-Width: 880px) {
                width: 80%;
            }
            &-error{
                width: 100%;
                min-height: 12%;
                margin-bottom: 5px;
                &-container{
                    position: relative;
                    display: flex;
                    width: 100%;
                    
                    text-align: center;
                    padding: 5px 10px;
                    background-color: red;
                    color: #fff;
                    // margin-bottom: 5px;
                    font-size: 0.9rem !important;
                    &-close{
                        right: 1%;
                        cursor: pointer;
                        font-size: 1.1rem;
                        top: -5%;
                        position: absolute;
                        transform: rotate(45deg);
                    }
                }
                
            }
            &-form{
                max-height: 470px;
                min-height: 270px;
                max-width: 600px;
                min-width: 300px;
                border-radius: 8px;
                border: 1px solid #D80C18;
                @media (max-Width: 880px) {
                    // width: 80%;
                    box-shadow: 0px 0px 50px 2px rgba(242, 103, 41, 0.1);
                }
                color: #595757;
                padding: 15px;
                height: 90%;
                display: flex;
                justify-content: space-around;
                flex-direction: column;
    
                &-text{
                    &-head{
                        font-size: 1.7rem;
                    }
                    &-text{
                        font-size: 1rem;
                    }
                }
               
                &-input{
                    input{
                        outline: none;
                        border: none;
                        width: 95%;
                        padding:  8px;
                        font-size: 1.1rem;
                    }
                        &-email,&-password{
                            padding: 4px;
                            border: 1px solid #D8D8D8;
                            border-radius: 4px;
                        }
                        &-password{
                            margin-top: 10px;
                            display: flex;
                            padding: 5px;
                            img{
                                cursor: pointer;
                                margin-left: 5px;
                                width: 18px;
                            }
                        }
                }
    
                &-bottom{
                    display: flex;
                    flex-direction: column;
                    &-submit{
                        background-color: #595757;
                        outline: none;
                        border: none;
                        padding: 15px 5px;
                        font-size: 1.1rem;
                        color: white;
                        cursor: pointer;
                        &:disabled{
                            cursor:auto;
                            background-color: gray;
                        }
                    }
                    &-forget{
                        margin-top: 5px;
                        font-size: 1rem;
                        text-align: center;
                        cursor: pointer;
                    }
                }
               
            }
    
            &-terms{
                margin-top: 10px;
                text-align: center;
                span{
                    cursor: pointer;
                    color: #F26729;
                    font-size: inherit;
                }
                font-size: 0.7rem;
            }
            
        }
    }
    
}