#toolbar{
    width: 100%;
}
.popup{
    background-color: rgb(225, 223, 223) !important;
}
.Read-more{
    width: 80px;
    font-size: 0.75rem;
    margin-top: -5px;
    cursor: pointer;
    padding: 4px 0px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: #595757;
}
.focus-Address{
    width: 80px;
    font-size: 0.75rem;
    margin-top: -5px;
    margin-left: 5px;
    cursor: pointer;
    padding: 4px 0px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: #595757;
}

.ql-toolbar.ql-snow .ql-formats{
    margin: 0px !important;
}

.ql-read{
    width: 80px !important;
}
.ql-address{
    width: 80px !important;
}
.ql-popup{
    margin-left: 5px;
    width: 80px !important;
}
.ql-address{
    width: 80px !important;
}
.ql-types{
   
    border: 1px #252D59 solid ;
    margin-left: 20px;
    font-size: 0.9rem !important;
    width: 120px !important;
}

.btn-format{

    position: absolute;
    top: -1%;
    right: 1.2%;
    
}

// .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options{
//     ::before{
//         content: attr(data-label);
//     }
// }
// .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label{
//     ::before{
//         content: attr(data-label);
//     }
// }
.ql-snow .ql-picker{
    border-radius: 5px ;
    ::before{
        content: attr(data-label);
    }
}

.ql-picker-label{
    overflow: hidden;
}