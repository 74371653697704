.homepage{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    font-size: 1.4rem;
    position: relative;
    color: #595757;
    @media (max-width: "700px") {
        font-size: 1rem;
        
    }
    @media (max-width: "500px") {
        font-size: 1.2rem;
        
    }
    &-analytics{
        // width: 100%;
        // padding: 30px;
        border-radius: 15px;
        color: white;
        padding: 15px;
        @media (min-width: "1200px") {
            padding: 30px 30px;
            
        }
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 100%;
        // height: 220px;
        background-image: url("../components/assets/svg/Homeback.svg");
        background-size: cover;
        // object-fit: contain;
        background-repeat: no-repeat;
        
        &-top{
            margin-bottom: 15px;
            &-left{
                font-weight: 600;
                img{
                    margin-left: 10px;
                }
            }
            &-right{
                input{
                    cursor: pointer;
                    background-color: transparent;
                    border: 1px solid white;
                    color: white;
                    font-size: inherit;
                    border-radius: 20px;
                    padding: 5px 10px;
                    option{
                        color: black;
                        cursor: pointer;
                    }
                    &::placeholder{
                        color: rgb(245, 245, 245);
                    }
                    outline: none;
                }
            }
            display: flex;
            justify-content: space-between;
        }
        &-bottom{
            height: 60%;
            font-size: inherit;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            div{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
                
                text-decoration: none;
                color: inherit;
                a{
                    display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-around;
                cursor: pointer;
                text-decoration: none;
                color: inherit;
                }
                
            }
            &-num{
                // margin-top: 20px;
                font-size: 3rem;
            }
        }
    }

    &-input{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 15px;
        &-container{
            display: flex;
            align-items: center;
            background-color:  #EBEBEB;
            padding: 5px 15px;
            width: 35%;
            position: relative;
            img{
                width: 18px;
                margin-right: 5px;
            }
            input{
                background-color: transparent;
                outline: none;
                padding: 8px 8px;
                border: none;
                font-size: 1.1rem;
                width: 100%;
            }
            
        }
    }
    &-table{
        width: 100%;
        margin-top: 15px;
        &-head{
            display: flex;
            justify-content: space-between;
            background-color: #252D59;
            padding: 10px 0px;
            
            span{
                color: white;
                width: 25%;
                text-align: center;
            }
        }

        // &-data{
        //     display: flex;
        //     justify-content: space-between;
        //     border-bottom: 1px solid black;
        //     padding: 15px 0px;
        //     font-size: 0.95rem;
        //     span{
        //         // color: white;
        //         display: flex;
        //         justify-content: center;
        //         align-items: center;
        //         width: 25%;
        //         text-align: center;
        //     }
           
        //     &-action{
        //         display: flex;
        //         align-items: center;
        //         text-align: left !important;
        //         justify-content: space-between !important;
        //         &-text{
        //             margin-right: 2px;
        //             display: flex;
        //             flex-wrap: wrap;
        //             &-type{
        //                 text-transform:capitalize;
        //                 margin-right: 5px;
        //                 font-weight: 500;
        //                 &-added{
        //                     color: #00A41B;
        //                 }
        //                 &-deleted{
        //                     color: red;
        //                 }
        //                 &-created{
        //                     color: #239536;
        //                 }
        //                 &-updated{
        //                     color: orange;
        //                 }
        //                 &-restored{
        //                     color: #008080
        //                 }
        //             }
        //             // flex-direction: column !important;
        //             // text-align: justify;
        //             // width: 100% !important;
        //             // text-align: left;
        //             // justify-content: space-between !important;
        //         }
        //         &-btn{
        //             display: flex !important;
        //             justify-content: flex-end !important;
        //             width: 20% !important;
        //         }
        //         img{
        //             cursor: pointer;
        //         }
        //     }
        // }
    }
    &-page{
        display: flex;
        width: 100%;
        font-size: 1rem;
        margin-top: 5px;
        justify-content: flex-end;
    }
    &-btns{
        margin-top: 20px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        div{
            display: flex;
            align-items: center;
            font-size: 1rem;
            cursor: pointer;
        }
        
        span{
            cursor: pointer;
            margin: 5px;
            background-color: #252D59;
            color: white;
            font-size: 2rem;
            width: 35px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
        }
    }

    &-model{
        &-close{
            position: absolute;
            top: -15px;
            right: -15px;
            width: 45px;
            height: 45px;
            background-color: #7f7f7f;
            color: white;
            font-size: 1.6rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            transform: rotate(45deg);
            cursor: pointer;
        }
        position: fixed;
        top: 50%;
        left: 60%;
        transform: translate(-50%, -50%);
        background-color: white;
        width: 70%;
        height: 70%;
        display: flex;
        justify-content: space-around;
        padding: 15px;
        font-size: 1rem;
        box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
       
        &-container{
        padding: 5px;
        width: 100%;
        max-height: 70%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
         overflow: auto;
        // text-align: center;
        &-change{
            margin: 5px 0px;
            background-color: #dfeefa;
            border-radius: 5px;
            padding: 5px 5px;
            min-width: 100%;
        }
        &-heading{
            margin-top: -4px;
            font-size: 1.4rem;
            text-transform: capitalize;
            margin-right: 10px;
        }


        }

        &-prev{
            width: 50%;
            
            &-head{
                font-size: 1.3rem;
                display: flex;
                width: 100%;
                text-align: center;
                justify-content: center;
                font-weight: 500;
            }
        }
        &-curr{
            width: 50%;
            &-head{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100% !important;
                text-align: center;
                font-size: 1.3rem;
                font-weight: 500;
            }
        }
    }
}
.datalist-input {
    background-color: aqua;
}