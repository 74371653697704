.update{
    position: fixed;
    top: 50%;
    left: 60%;
    font-size: 1.2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    @media (max-width: 1100px) {
        left: 50%;
    }
    &-heiglight{
        color: #2ea1ff;
    }
    transform: translate(-50%, -50%);
    background-color: #ffff;
    min-height: 250px;
    min-width: 500px;
    box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 15px;
    text-align: center;
    color: #252D59;
    font-weight: 600;

    &-btn{
        
        display: flex;
        align-items: center;
        justify-content: space-around;
        span{
            width: 135px;
            // height: 50%;
            padding: 20px 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border-radius: 5px;
        }
        &-yes{
            background-color: #F0F6FB;
           
            
            
        }
        &-no{
            color:  #FF4040;
            background-color: #FCC1C1;
        }
    }
   
}