.dlogs{
    &-input{
       
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        &-container{
            margin: 15px 0px;
            display: flex;
            align-items: center;
            background-color:  #E3EFF7;
            padding: 5px 15px;
            width: 50%;
            position: relative;
            img{
                width: 18px;
                margin-right: 5px;
            }
            input{
                background-color: transparent;
                outline: none;
                padding: 8px 8px;
                border: none;
                font-size: 1.1rem;
                width: 100%;
            }
            button{
                border: none;
                cursor: pointer;
                padding: 5px 15px;
                border-radius: 5px;
                background-color: #252D59;
                color: #fff;
                outline: none;
                &:disabled{
                  opacity: none;
                  display: none;
                }
            }
            
        }
      }
   
}