
.no-suggestions {
    color: #999;
    padding: 0.5rem;
    position: absolute;
    background: #E3EFF7;
    font-size: 0.9rem;
    top: 108%;
    width: 100%;
    left: 0%;
  }
  
  .suggestions {
    border: 1px solid #999;
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 143px;
    overflow-y: auto;
    padding-left: 0;
    width: calc(300px + 1rem);
    position: absolute;
    background: #EBEBEB;
    color: #595757;
    font-size: 0.9rem;
    top: 108%;
    width: 100%;
    left: 0%;
    font-weight: 500;
  }
  
  .suggestions li {
    padding: 0.5rem;
  }
  
  .suggestion-active,
  .suggestions li:hover {
    background-color: #595757;
    color: white;
    cursor: pointer;
    font-weight: 500;
  }
  
  .suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #999;
  }