.notification{
    z-index: 99999;
    
    &-popup{
        top: 3%;
        right: 3%;
        position: fixed;
        // width: 150px;
        font-size: 1rem;
        padding: 10px 15px;

        text-align: center;
        &-success{
            background-color: rgb(30, 164, 106);
        }
        &-error{
            background-color: red;
        }
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        animation: moveTop 1s;
        color: white;
        span{
            top: -10%;
            right: 1%;
            position: absolute;
            transform: rotate(45deg);
            cursor: pointer;
            font-size: 1.2rem;
            color: white;
        }
    }
}