.table {

  // ============================================================ homepage action
  &-data {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid black;
    padding: 15px 0px;
    font-size: 0.95rem;

    span {
      // color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25%;
      text-align: center;
    }

    &-action {
      display: flex;
      align-items: center;
      text-align: left !important;
      justify-content: space-between !important;

      &-text {
        margin-right: 2px;
        display: flex;
        flex-wrap: wrap;

        &-type {
          text-transform: capitalize;
          margin-right: 5px;
          font-weight: 500;

          &-added {
            color: #00A41B;
          }

          &-deleted {
            color: red;
          }

          &-created {
            color: #239536;
          }

          &-updated {
            color: #D80C18;
          }

          &-restored {
            color: #008080
          }
        }

        // flex-direction: column !important;
        // text-align: justify;
        // width: 100% !important;
        // text-align: left;
        // justify-content: space-between !important;
      }

      &-btn {
        cursor: pointer;
        display: flex !important;
        justify-content: flex-end !important;
        width: 20% !important;
      }

      img {
        cursor: pointer;
      }
    }

  }

  // +++++++++++++++++++++++++++++++++++++++++++ End Homepage Action

  &-file-upload {
    display: none;
  }

  // ========================================== start search input container
  &-input {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-container {
      // margin: 15px 0px;
      margin-top: 15px;
      display: flex;
      align-items: center;
      background-color: #EBEBEB;
      padding: 5px 15px;
      width: 50%;
      position: relative;

      img {
        width: 18px;
        margin-right: 5px;
      }

      input {
        background-color: transparent;
        outline: none;
        padding: 5px 8px;
        border: none;
        font-size: 1.1rem;
        width: 100%;
      }

      button {
        border: none;
        cursor: pointer;
        padding: 5px 15px;
        border-radius: 5px;
        background-color: #252D59;
        color: #fff;
        outline: none;

        &:disabled {
          opacity: none;
          display: none;
        }
      }

    }
  }

  &-container {
    width: 100%;
    margin-top: 15px;

    &-head {
      display: flex;
      justify-content: space-between;
      background-color: #595757;
      padding: 10px 0px;

      &-feedback {
        width: 40% !important;
      }

      &-sort {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 18px;
          padding: 2px 2px;
          cursor: pointer;
          border: 1px solid white;
          margin-left: 8px;
          border-radius: 5px;
        }
      }

      span {
        color: white;
        width: 25%;
        text-align: center;
        justify-content: center !important;
      }
    }

    &-data {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #595757;
      padding: 15px 0px;
      font-size: 0.95rem;

      span {
        // color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25%;
        text-align: center;
        color: #595757
      }

      &-context {
        width: 65% !important;
        justify-content: flex-start !important;
        margin-left: 9.5%;

        &-table {
          display: flex;
          margin-left: 10px;
          justify-content: space-between !important;
          width: 24% !important;

          &-status {
            font-weight: 500;

            &-resolved {
              color: green;
            }

            &-removed {
              color: red;
            }
          }

          &-text {
            justify-content: flex-start !important;
            width: 95% !important;
            margin-left: 5px;
            text-align: left !important;
            overflow-wrap: anywhere;
          }

          img {
            cursor: pointer;
            width: 20px;
          }
        }
      }

      &-action {
        display: flex;
        align-items: center;
        text-align: left !important;
        justify-content: space-between !important;

        &-btn {
          display: flex !important;
          justify-content: flex-end !important;
          width: 20% !important;
        }

        img {
          cursor: pointer;
        }
      }

      &-form {
        display: contents;

        select {
          padding: 5px;
        }

        input {
          width: 100%;
          margin: 5px;
          padding: 5px;
        }
      }
    }

    &-empty {
      width: 100%;
      margin-top: 15px;
      font-size: 1.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &-page {
    display: flex;
    width: 100%;
    font-size: 1rem;
    margin-top: 5px;
    justify-content: flex-end;
    align-items: center;

    &-no {
      margin-left: 5px;
      font-size: 1rem;
      border-radius: 5px;
      padding: 3px 10px;
      background-color: #EBEBEB;
    }
  }

  &-btns {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: flex-end;

    div {
      display: flex;
      align-items: center;
      font-size: 1rem;
      cursor: pointer;
    }

    span {
      cursor: pointer;
      margin: 5px;
      background-color: #595757;
      color: white;
      font-size: 2rem;
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
    }
  }

  &-heading {
    margin-top: 20px;
    color: #595757;

    &-btn {
      margin-top: auto;
      display: flex;
      // margin-bottom: 18px;

    }

    &-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0px;

      &-titleinfo {
        margin: 10px 0px;
        width: 100%;
        &-date{
          width: 100%;
          display: flex;
          justify-content: center;
          padding-bottom: 10px;
          font-size: 1.2rem;
        }
        &-list {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }

        span {
          font-size: 1rem !important;
        }

      }

    }

    &-date {
      // width: 50%;
      display: flex;
      align-items: center;

      &-picker {
        position: absolute;
        right: 10%;
        width: 220px;
      }

      input {
        border-radius: 10px;
        border: 1px solid #252D59;
        padding: 5px 10px;
      }

      &-export {
        margin-right: 3px;
        font-size: 0.8rem !important;
        cursor: pointer;
        display: flex;
        color: white;
        align-items: center;
        border-radius: 5px;
        margin-left: 8px;
        padding: 5px;
        background-color: #595757;

        img {
          // margin-right: 1px;
          margin-left: 5px;
          width: 15px;
        }

        &-disabled {
          pointer-events: auto;
          background-color: gray;
          color: white;
        }
        &-disabled:active {
          pointer-events: none;
        }
      }
    }

    &-SearchWithBtnsContainer {
      display: flex;
      justify-content: space-between;
    }

    span {
      font-size: 1.2rem;
    }
  }

  // custome class name by type
  &-isin-client {
    width: 45% !important;
    justify-content: flex-start !important;
    text-align: left !important;
  }

  &-restore-username {
    display: flex;
    justify-content: space-around !important;
  }

  &-restore-btn {
    padding: 0px 8px;
    border-radius: 5px;
    width: max-content !important;
    font-size: 1.4rem;
    cursor: pointer;
    color: #fff !important;
    background-color: #595757;
  }

  &-edit-username {
    display: flex;
    justify-content: space-around !important;
  }

  &-edit-btn {
    // display: flex;
    // padding: 5px;
    // border-radius: 5px;
    width: max-content !important;

    // // font-size: 1.4rem;
    // cursor: pointer;
    // // color: #fff;
    // background-color: #252D59;
    img {
      width: 18px;
    }
  }

  &-edit-name {
    display: flex;
    justify-content: space-around !important;
  }

  &-edit-btn {
    padding: 4px 5px;
    // margin: 0px 8px;
    border-radius: 5px;
    width: max-content !important;
    font-size: 0.8rem;
    cursor: pointer;
    color: #fff;
    background-color: #595757;
  }

  &-deletedlogs-align {
    justify-content: flex-start !important;
    text-align: left !important;
    padding-left: 20px;
  }

  &-frequently-name {
    width: 65% !important;
    justify-content: flex-start !important;
    margin-left: 9.5%;
  }

  &-isin-isin_no {
    flex-wrap: wrap;
    // row-gap: 5px;
    width: 35% !important;
    text-align: left !important;

    input {
      margin: 5px;
      margin-left: -5px;
      width: 115px !important;
    }
  }

  &-isin-cin_no {
    width: 35% !important;
    justify-content: flex-start !important;
    // text-align: left !important;
  }

  &-hompage-action {
    text-align: left !important;
    justify-content: space-between !important;
  }

  &-width-20 {
    width: 20% !important;
  }

  &-width-30 {
    width: 30% !important;
  }

  &-width-35 {
    width: 35% !important;
  }

  &-number-list {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;

    li {
      text-align: left;
      padding-top: 5px;
    }
  }
}