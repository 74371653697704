.home{
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    padding: 40px 70px;
    @media (max-width: "1100px") {
        min-height: 90vh;
        padding: 30px 30px;
    }
    @media (max-width: "770px") {
        padding: 20px 15px;
    }
}